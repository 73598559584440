<template>
    <div class="overflow-hidden bg-blue-dark h-screen relative " style="height: 540px">
        <div class="big-blue  bg-blue-dark top-0 h-full z-0">
            <svg class="fill-current text-blue-light" viewBox="0 0 1101 1101" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="550.5" cy="550.5" r="550.5" fill="currentFill"/>
            </svg>
        </div>
        <div class="absolute h-full z-0" style="bottom: -80px; right: 0px;">
            <svg class="fill-current text-pink " width="540"
                 viewBox="0 0 200 200">
                <circle cx="100" cy="100" r="75"/>
            </svg>
        </div>
        <div class="relative max-w-3xl mx-auto text-white z-10 h-full flex flex-col justify-center items-start px-8 sm:px-4">
            <h1 class="font-heading font-bold text-5xl lg:text-7xl leading-none mb-8">The <br> Wake Up Call</h1>
            <span class="uppercase">from</span>
            <h3 class="font-heading font-bold text-3xl text-blue-dark leading-tight">The Sleep Council & <br> The Sleep Charity</h3>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Hero"
    }
</script>
