<template>
    <div class="bg-blue-light text-center py-10 flex flex-col items-center">
        <img class="w-56" src="images/svg/furniture-village-logo.svg" alt="">
        <p class="max-w-xs">Event refreshments kindly sponsored by Furniture Village</p>
    </div>
</template>

<script>
    export default {
        name: "Sponsor"
    }
</script>
