import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

import './assets/tailwind.css'

Vue.config.productionTip = false


import Privacy from './views/Policies/Privacy'
import TermsAndConditions from "./views/Policies/TermsAndConditions";
import Index from "./views/Home/Index";

const routes = [
  {
    path: '/',
    name: 'home',
    component: Index
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: Privacy
  },
  {
    path: '/terms-conditions',
    name: 'terms-conditions',
    component: TermsAndConditions
  },
  {
    path: '/*',
    redirect: '/'
  }
]

const router = new VueRouter({
  mode: "history",
  routes, // short for `routes: routes`
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
})

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
