<template>
    <div class="py-12 " :class="{'bg-blue-dark' : dark, 'bg-pink' : pink}">
        <div class="max-w-3xl mx-auto px-8 sm:px-4 h-full">
            <h3 class=" font-bold font-heading text-4xl"
                :class="{'text-pink' : dark, 'text-blue-dark' : pink}"
            >The Sleep Manifesto 2020</h3>
            <div class="flex justify-center flex-col sm:flex-row items-start mt-6">
                <div class="w-full sm:w-full md:w-1/2">
                    <p class="text-white font-heading text-xl">
                        Download The Sleep Manifesto 2020, packed with insights and information
                    </p>
                    <a href="/downloads/the-sleep-manifesto-manifesto-january-2020_digital.pdf"
                       class="table bg-white rounded-full py-4 px-10 mt-6 hover:text-white transition-all ease duration-150"
                       target="_blank"
                            :class="{'hover:bg-pink' : dark, 'hover:bg-blue-dark' : pink}"
                    >Download manifesto
                    </a>
                </div>
                <div class="w-full sm:w-full md:w-1/2 mt-8 sm:mt-0">
                    <p class="text-white font-heading text-xl">
                        Download The Sleep Charter, key points and information from the manifesto
                    </p>
                    <a href="/downloads/the-sleep-manifesto-charter-january-2020_digital.pdf"
                       class="table bg-white rounded-full py-4 px-10 mt-6 hover:text-white transition-all ease duration-150"
                       target="_blank"
                            :class="{'hover:bg-pink' : dark, 'hover:bg-blue-dark' : pink}"
                    >Download charter
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Downloads",
        props: {
            dark: {
                type: Boolean,
                default: false
            },
            pink: {
                type: Boolean,
                default: false
            },
        },
    }
</script>
