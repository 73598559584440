<template>
    <div id="home">
        <Hero/>

        <About/>
        <CallToActionQuote/>
        <Downloads :dark="true"/>
        <Manifesto/>
        <Sponsor/>
        <TheOrganisation/>
        <Downloads :pink="true"/>
        <ContactForm/>

    </div>
</template>

<script>
    import Hero from "./components/Hero";
    import About from "./components/About";
    import CallToActionQuote from "./components/CallToActionQuote";
    import Downloads from "./components/Downloads";
    import Manifesto from "./components/Manifesto";
    import Sponsor from "./components/Sponsor";
    import TheOrganisation from "./components/TheOrganisation";
    import ContactForm from "./components/ContactForm";

    export default {
        name: "Index",
        components: {ContactForm, TheOrganisation, Sponsor, Manifesto, Downloads, CallToActionQuote, About, Hero}
    }
</script>

