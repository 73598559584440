<template>
    <div class="text-center py-12 bg-pink px-8 sm:px-4">
        <div class="max-w-3xl mx-auto">
            <p class="font-bold text-white font-bold font-heading text-4xl leading-tight">“Our vision is to raise the profile of sleep as a major factor for health and wellbeing and to ensure
                that sleep becomes a key issue on the Public Health Agenda.”
            </p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "CallToActionQuote"
    }
</script>

