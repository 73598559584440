<template>
    <div class="bg-blue-dark py-10">
        <div class="max-w-3xl mx-auto px-8 sm:px-4">
            <div class="flex justify-between items-stretch sm:mt-6">
                <div class="w-full md:w-1/2 sm:mt-0 flex justify-between flex-col">
                    <div>
                        <img class="w-40" src="images/svg/fr-regulator.svg" alt="">
                    </div>
                    <div>
                        <div class="text-pink font-thin font-heading text-sm">
                            <router-link :to="{ name: 'privacy-policy'}">
                                Privacy Policy
                            </router-link>
                            |
                            <router-link :to="{ name: 'terms-conditions'}">
                                Terms & Conditions
                            </router-link>
                        </div>
                        <div class="text-white font-bold font-heading text-sm mt-2">
                            <a href="mailto:info@thesleepcharity.org.uk">info@thesleepcharity.org.uk</a> |
                            <a href="mailto:info@sleepcouncil.co.uk">info@sleepcouncil.co.uk</a>
                        </div>
                        <p class="text-pink font-heading font-thin text-sm leading-tight mt-2">Copyright 2020 © The
                            Sleep Charity | The Sleep Council</p>
                    </div>
                </div>
                <div class="w-full sm:w-full md:w-1/2 mt-8 sm:mt-0">
                    <div class="flex justify-start sm:justify-end">
                        <img class="h-28" src="images/svg/footer-logos.svg"/>
                    </div>
                    <p class="text-pink text-left sm:text-right font-heading font-thin text-sm leading-tight mt-4">The
                        Sleep Charity is a charitable incorporated organisation registered with the Charity Commission
                        under registration number 1150585 </p>
                    <p class="text-white text-left sm:text-right  font-heading font-thin text-sm leading-tight mt-2">
                        Website design by
                        <a href="https://pip-creative.com" target="_blank" class="font-bold"> PIP</a></p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Footer"
    }
</script>