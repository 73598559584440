<template>
    <div class="w-full flex justify-center absolute top-0 z-50">
        <div class="bg-white w-full absolute h-6 z-10"></div>
        <router-link :to="{ name: 'home'}" class="bg-white flex flex-row justify-center items-center absolute z-20">
            <div id="sleep-council-logo" class="flex items-center justify-center w-full bg-white w-48 border-r border-gray-300">
                <img class="h-24" src="images/svg/sleep-council-logo.svg"/>
            </div>
            <div id="sleep-charity-logo" class="w-full px-4 py-1">
                <img class="h-28" src="images/svg/sleep-charity-logo.svg"/>
            </div>
        </router-link>
    </div>
</template>

<script>
    export default {
        name: "Header"
    }
</script>
