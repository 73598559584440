<template>
    <div class="py-12 bg-gray-100">
        <div class="max-w-3xl mx-auto px-8 sm:px-4 h-full">
            <h3 class="text-pink font-bold font-heading text-3xl sm:text-4xl">About the organisations</h3>
            <div class="flex justify-center flex-col sm:flex-row items-start mt-6">
                <div class="w-full sm:w-full md:w-1/2 mt-8 sm:mt-0">
                    <img class="h-28" src="images/svg/sleep-charity-logo.svg"/>
                    <p class="text-blue-dark font-heading text-xl font-bold mt-4">
                        Vicki Dawson - CEO, The Sleep Charity </p>
                    <p class="text-blue-dark font-heading font-thin "> The Sleep Charity is a national
                        award-winning charity, set up to support children, young people
                        and their families with sleep issues. It provides accredited training for professionals and
                        families, is actively involved in research projects and aims to raise the profile of the
                        importance of sleep for young people.

                        Download The Sleep Charter, key points and information from the manifesto</p>
                    <a href="http://thesleepcharity.org.uk/" target="_blank" class="inline-block bg-blue-light text-white rounded-full py-4 px-10 mt-6 hover:bg-pink hover:text-white transition-all ease duration-150">
                        Find out more
                    </a>

                </div>
                <div class="w-full sm:w-full md:w-1/2 mt-8 sm:mt-0">
                    <img class="h-28" src="images/svg/sleep-council-logo.svg"/>
                    <p class="text-blue-dark font-heading text-xl font-bold mt-4">
                        Lisa Artis - CEO, The Sleep Council</p>
                    <p class="text-blue-dark font-heading font-thin"> The Sleep Council is an independent
                        authority on sleep health and has been sharing tips and tools for restorative sleep, supporting
                        research, moving sleep up the public health agenda and growing partnerships to amplify its
                        impact for 25 years.</p>


                    <a href="https://sleepcouncil.org.uk/"  target="_blank" class="inline-block bg-pink text-white rounded-full py-4 px-10 mt-6 hover:bg-blue-light hover:text-white transition-all ease duration-150">
                        Find out more
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "TheOrganisation"
    }
</script>
