<template>
    <div class="max-w-3xl mx-auto policy-content  px-8 sm:px-4">
        <h3>Privacy Policy</h3>

        <p> We take this stuff seriously – and like to do things properly – so if you would like to read the legal
            information relating to our Privacy Policy, hold on to your hats and read on…
        </p>

        <p> The Sleep Charity and The Sleep Council are independent brands. Both are committed to protecting and
            respecting your privacy. For the purposes of the General Data Protection Regulations (GDPR) and any
            subsequent UK legislation covering data protection the Data Controllers are The Sleep Charity & The Sleep
            Council.
        </p>
        <p>This Policy (together with our terms of use and any other documents referred to on it) sets out the basis on
            which any personal data we collect from you, or that you provide to us, will be used by us. It explains the
            legal basis for this and the rights you have over the way your information is used.
        </p>

        <p>This Policy covers the organisations and associated brands in relation to the collection and use of the
            information you give us. We may change this Policy from time to time. If we make any significant changes we
            will advertise this on the website or contact you directly with the information. Please check this page
            occasionally to make sure you are happy with any changes.
        </p>

        <p>If you have any questions about this Policy or concerning your personal information please contact us via the
            website contact us form or email <a href="mailto:info@sleepcouncil.co.uk">info@sleepcouncil.co.uk</a>
        </p>


        <h3> What type of personal information we collect</h3>

        <p> The type and amount of information we collect depends on why you are providing it:</p>
        <ul>
            <li> The information we collect when you make an enquiry includes your name, email address, and in some
                cases where relevant your postal address and phone number.
            </li>

            <li> The information we collect when you sign up to our email newsletter lists includes your name and email
                address.
            </li>

            <li> If you contact us, we may keep a record of that correspondence.</li>

            <li> If you are a customer, for example making a product purchase, using our services or signing up for an
                event in addition to asking for your name and contact details (your full address, email address and your
                phone number) we may also ask you for additional information during the signup process but this is not
                classified as personal information.
            </li>

            <li> We will never store your credit/debit card information. Where a purchase or payment is made online your
                card information will be submitted to secure payment providers Paypal or Stripe – this information is
                never stored by us.
            </li>

            <li> If you are a job applicant the information you are asked to provide is set out in the application and
                necessary for the purposes of our considering the application.
            </li>
        </ul>

        <h3>How we collect information</h3>

        <p>We may collect information from you whenever you contact us or have any involvement with us for example when
            you:</p>

        <ul>
            <li>visit our website (see our Cookies policy)</li>
            <li>enquire about our activities or services</li>
            <li>sign up to receive news about our activities</li>
            <li>create or update a profile online</li>
            <li>make a purchase</li>
            <li>work or volunteer for us</li>
            <li>attend a meeting with us and provide us with information</li>
            <li>take part in our events</li>
            <li>contact us in any way including online, email, phone, SMS, social media or post</li>
        </ul>

        <h3>Where we collect information from</h3>

        <p>We collect information:</p>

        <p> From you when you give it to us directly: You may provide your details when you ask us for information, make
            a
            purchase, sign up to our website/service, volunteer, attend our events or contact us for any other reason.
            When you give it to us indirectly: Your information may be shared with us by other organisations for us to
            fulfil a contract – i.e You sign up to an event with us via Eventbrite or we’re sending a product out on
            behalf
            of a business you are a customer of. They should only do so in the way they have set out in their own
            Privacy
            Policy which you should check when you give your details.</p>

        <p> When you have given other organisations permission to share it: Your information may be provided to us by
            other
            organisations if you have given them your permission. This might for example be when you buy a product or
            service from a third party organisation. The information we receive from other organisations depends on your
            settings or the option responses you have given them.</p>

        <p> When you use our website: When you use our website information about you is recorded and stored. See the
            information about the use of cookies under that heading below.</p>

        <p> When it is in available on social media: Depending on your settings or the privacy policies applying for
            social
            media and messaging services you use, like Facebook, Instagram or Twitter, you might give us permission to
            access information from those accounts or services.</p>


        <h3>How we use your information</h3>

        <p>We will use your personal information in a number of ways which reflect the legal basis applying to
            processing
            of your data. These may include:</p>

        <ul>
            <li>providing you with the information or services you have asked for</li>
            <li>processing orders you make</li>
            <li>organising activity you have told us you want to be involved in</li>
            <li>to carry out our obligations arising from any contracts entered into between you and us</li>
            <li>sending you communications with your consent that may be of interest including marketing information
                about
                our
            </li>
            <li>services and activities, campaigns and events</li>
            <li>seeking your views on the services or activities we carry on so that we can make improvements</li>
            <li>maintaining our organisational records and ensuring we know how you prefer to be contacted</li>
            <li>analysing the operation of our website and analysing your website behaviour to improve the website and
                its
            </li>
            <li>usefulness</li>
            <li>processing job applications</li>
            <li>If we send you newsletters by emails or promotional emails these will always contain an unsubscribe link
                giving
            </li>
            <li>you the opportunity to opt out from future emails.</li>
        </ul>

        <h3>Our legal basis for processing your information</h3>

        <p>The use of your information for the purposes set out above is lawful because one or more of the following
            applies:</p>

        <p> Where you have provided information to us for the purposes of requesting information or requesting that we
            carry out a service for you, we will proceed on the basis that you have given consent to us using the information
            for that purpose, based on the way that you provided the information to us. You may withdraw consent at any time
            by emailing us at info@sleepcouncil.co.uk. This will not affect the lawfulness of processing of your
            information prior to your withdrawal of consent being received and actioned.</p>

        <p> It is necessary for us to hold and use your information so that we can carry out our obligations under a
            contract entered into with you or to take steps you ask us to prior to entering into a contract.
            Where it is necessary for us to share specific information with a third party in order to fulfil our
            contract with you (i.e. working with a supplier to deliver goods to you) we may rely on the fact that it is necessary
            for your legitimate interest in order to deliver the contract.</p>

        <p> Where the purpose of our processing is to carry out our obligations under a contract entered into with a
            third party (i.e. sending an item on their behalf) we may rely on the fact that it is necessary for the legitimate
            interests of that third party in order to deliver the contract.</p>

        <p> Where the purpose of our processing is the provision of information or services to you, we may also rely on
            the fact that it is necessary for your legitimate interests that we provide the information or service
            requested, and given that you have made the request, would presume that there is no prejudice to you in our fulfilling
            your request.</p>

        <p> If you want to contact us about your marketing preferences please contact our office on
            info@sleepcouncil.co.uk</p>
        <p> How we keep your information safe</p>
        <p> We understand the importance of security of your personal information and take appropriate steps to
            safeguard it.</p>
        <p> We always ensure only authorised persons have access to your information, which means only our staff,
            volunteers and contractors, and that everyone who has access is appropriately trained to manage your information.</p>

        <p> No data transmission over the internet can however be guaranteed to be 100% secure. So while we strive to
            safeguard your information, we cannot guarantee the security of any information you provide online and you
            do this at your own risk.</p>


        <h3>Who has access to your information?</h3>

        <p> Third parties who provide services for us, for example product providers. We select our third party service
            providers with care. We provide these third parties with the information that is necessary to provide the
            service and we will have an agreement in place that requires them to operate with the same care over data
            protection as we do.</p>
        <p>Third parties if we run an event in conjunction with them. We will let you know how your data is used when
            you register for any event.</p>
        <p>Analytics, marketing and search engine providers that help us to improve our website and its use.
            Third parties in connection with the sale, restructuring or reorganisation of our operations. In such event
            we will take steps to ensure your privacy rights will be protected by the third party.</p>

        <p>Owing to matters such as financial or technical considerations the information you provide to us may be
            transferred to countries outside the European Economic Area (EEA), which are not subject to the same data
            protection regulations as apply in the UK. For example data may be stored on servers outside the EEA or be
            collected and stored within supplier applications (i.e. Mailchimp) which may be based outside the EEA. We
            meet our obligations under GDPR by ensuring that the information has equivalent protection as if it were being
            held within the EEA. We do this by ensuring that any third parties processing your data outside the EEA either
            benefits from an adequacy determination for GDPR purposes and/or, where appropriate, we have entered into a
            Data Processing Agreement which contains model EU clauses.</p>

        <p>We may also disclose your personal information if we are required to do so under any legal obligation and may
            use external data for the purposes of fraud prevention and credit risk reduction, or where doing so would
            not
            infringe your rights, but is necessary and in the public interest.</p>

        <p> Other than this, we will not share your information with other organisations without your consent.</p>

        <h3>Links to other websites</h3>

        <p>Our website may contain links to other websites of interest. However, once you have used these links to leave
            our site, you should note that we do not have any control over that other website.</p>

        <p>Accordingly, we cannot be responsible for the protection and privacy of any information which you provide
            whilst visiting such sites and such sites are not governed by this privacy statement. You should exercise caution
            and look at the privacy statement applicable to the website in question before submitting any personal date to
            it.</p>

        <h3>Keeping your information up to date</h3>

        <p>We really appreciate it if you let us know if your contact details change. You can do so by contacting us at
            <a href="mailto:info@sleepcouncil.co.uk">info@sleepcouncil.co.uk</a></p>

        <h3>Our use of “cookies”</h3>

        <p> “Cookies” are small pieces of information sent by a web server to a web browser, which enable the server to
            collect information from the browser. They are stored on your hard drive to allow our website to recognise
            you
            when you visit. Please read our cookies policy here Cookies policy</p>

        <h3>How long we keep your information for</h3>

        <p>We will hold your personal information for as long as it is necessary for the relevant activity. By way of
            example, we hold records of purchases you make for at least six years so we can fulfil our statutory
            obligations
            for tax purposes.</p>

        <p>Where we rely on your consent to contact you for direct marketing purposes, we will treat your consent as
            lasting only for as long as it is reasonable to do so. You will always be able to adjust your consent via
            our
            chosen email marketing provider (i.e. Mailchimp) but we may also periodically ask you to renew your
            consent.</p>

        <p>If you ask us to stop contacting you with marketing materials, we will keep a record of your contact details
            and
            limited information needed to ensure we comply with your request.</p>

        <h3>Your rights</h3>

        <p>You have the right to request details of the processing activities that we carry out with your personal
        information through making a Subject Access Request. Such requests have to be made in writing. The organisations
        may ask for any further information reasonably required to locate the information. To make a request contact us
            at <a href="mailto:info@sleepcouncil.co.uk">info@sleepcouncil.co.uk</a>.</p>

        <h3>You also have the following rights:</h3>
        <ul>

        </ul>
        <li>the right to request rectification of information that is inaccurate or out of date;</li>
        <li>the right to erasure of your information (known as the “right to be forgotten”);</li>
        <li>the right to restrict the way in which we are dealing with and using your information; and</li>
        <li>the right to request that your information be provided to you in a format that is secure and suitable for
            re-use (known as the “right to portability”);
        </li>

        <li>rights in relation to automated decision making and profiling including profiling for marketing purposes.
        </li>

        <p> All of these rights are subject to certain safeguards and limits or exemptions, further details of which can
            be found in our Data Protection Policy. To exercise any of these rights, you should contact the Office at the
            above address.</p>

        <p>If you are not happy with the way in which we have processed or dealt with your information, you can complain
            to the Information Commissioner’s Office. Further details about how to complain can be found here.</p>

        <h3>Changes to this Privacy Policy</h3>

        <p> This Policy may be changed from time to time. If we make any significant changes we will advertise this on
            our website or contact you directly with the information.</p>

        <p>Do please check this Policy each time you consider giving your personal information to us.</p>

        <p>This Policy was last updated on 10.02.2020</p>
    </div>
</template>

<script>

    export default {
        name: "Privacy",

    }
</script>
