<template>
    <div class="py-12 bg-white">
        <div class="max-w-3xl mx-auto px-8 sm:px-4 h-full">

            <h2 class="text-pink font-bold font-heading text-4xl">The Manifesto Launch</h2>
            <p class="font-heading font-light text-lg mt-8"> On Wednesday 4th March 2020, we launched our biggest ever call on the government to move sleep up the Public Health Agenda. We were joined by some fantastic speakers at the event, including...
            </p>
            <div class="" v-for="speaker in speakers" :key="speaker.name">
                <div class="flex mt-10">
                    <div class="w-32 pr-2">
                        <img class="flex w-full " :src="speaker.photo" alt="">
                    </div>
                    <div class="flex-1 pl-2">
                        <h4 class="text-blue-light text-2xl">{{speaker.name}}</h4>
                        <p class="font-bold font-heading">{{speaker.title}}</p>
                        <div class="font-thin font-heading" v-html="speaker.body"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Manifesto",
        data() {
            return {
                speakers: [
                    {
                        name: 'Clive Betts MP',
                        title: 'MP for Sheffield South East',
                        body: '<p>Clive Betts MP began his political career in 1973 and in 1976 was elected as a member of Sheffield City Council. He became Leader of the Council in 1987, having served on the Housing and Finance committees for several years. He remained on the Council until his election as Labour Member of Parliament for Sheffield Attercliffe in 1992. </p>' +
                            '<p class="mt-4">Clive was appointed a Government Whip in 1997 and held the post until 2001. In June 2010 he was elected as Chair of the Department for Communities and Local Government Select Committee. </p>' +
                            '<p class="mt-4">Since becoming a Member of Parliament, Clive has been a key member on several Select Committees (Department for Environment, Transport and the Regions, Office of the Deputy Prime Minister, Department for Communities and Local Government.) His political interests include economic policy, local and regional government and housing. He is chair of the All Party Parliamentary Group for Arms Length Management Organisations. Clive is also chair of the Netherlands Group in Parliament.</p>',
                        photo: 'images/clive-betts.png',
                    },
                    {
                        name: 'Tariq Shah',
                        title: 'Chair of The Sleep Charity\n',
                        body: '<p>Tariq was appointed Chair of the Sleep Charity in 2019 bringing strategic management and Third Sector experience to the Board. He is a Director of Vigo Group, a third-generation family business specialising in transformational real estate projects.</p>' +
                            '<p class="mt-4">In other Third Sector roles, Tariq is a Trustee of AHS Foundation, which provides primary healthcare and delivers hope to the people of Noon Bagla and the surrounding area in Azad Kashmir; and a founding board member of Mosaic. Mosaic, now part of The Prince’s Trust, was founded by HRH The Prince of Wales in 2007 to create opportunities for young people growing up in some of our most deprived communities.</p>',
                        photo: 'images/tariq-shah.png',
                    },
                    {
                        name: 'Dr Neil Bindemann',
                        title: 'Director of External Affairs, British Society of Lifestyle Medicine\n',
                        body: '<p>Neil is Director of External Affairs for British Society of Lifestyle Medicine and Executive Director of the Primary care and Community Neurology Society. He trained in both Immunology and Neurobiology and has a keen interest in developing science of neuro-immunology, especially concerning mental health.</p>' +
                            '<p class="mt-4">In 2015 Neil was diagnosed with a very rare benign brain tumour making his interest in neuroimmunology, mental health and communication much more personal. He has a passion for supporting patient-professional communication/relationships, exploring the developing concept of mental fitness and ultimately supporting quality of life.</p>',
                        photo: 'images/neil.png',
                    },
                    {
                        name: 'Mark Rowland',
                        title: 'CEO of Mental Health Foundation\n',
                        body: '<p>Mark was appointed as the Mental Health Foundation\'s new Chief Executive in October 2018. Prior to that, he was the Foundation\'s Director of Communications and Fundraising and brings a lived experience of being bereaved by suicide. Mark has spent 15 years working in communications, fundraising and campaigning around human rights and international development with a particular focus on children’s rights and poverty alleviation. He has lived in both Asia and Africa and has an MBA from the Open University.</p>' +
                            '<p class="mt-4">As CEO, Mark is focusing on building a vibrant organisation with a new strategy, focusing on prevention in mental health and working with a wide range of organisations to ensure that people across the UK can live mentally healthier lives. He sits on the Government’s National Suicide Prevention Strategy Advisory Group. He is married with a teenage son and loves distance running as a vital way to manage his mental health.</p>',
                        photo: 'images/mark.png',
                    },
                    {
                        name: 'Dr Michael Farquhar',
                        title: 'Consultant in Children’s Sleep Medicine, Evelina Hospital',
                        body: '<p>Mike has been a consultant at the Department of Children’s Sleep Medicine at Evelina London since 2012 where he holds clinics and supports the busy diagnostic sleep study service.</p>' +
                            '<p class="mt-4">He is also involved in educating healthcare professionals on the importance of sleep and fatigue in the workplace, particularly focusing on night shift workers. His work led to the introduction of the HALT: Take a Break campaign for Guys & St Thomas staff in 2017. He has worked with organisations including the Royal College of Paediatrics and Child Health and the Royal College of Nursing in this area. In 2018 he was awarded the Association Award by the AAGBI for his contribution to the #FightFatigue campaign.</p>',
                        photo: 'images/michael.png',
                    },
                    {
                        name: 'Prof. Heather Elphick',
                        title: 'Sheffield Children’s Hospital',
                        body: '<p>Heather is Lead Consultant in Paediatric Respiratory and Sleep Medicine at Sheffield Children’s Hospital. She is visiting Professor at Sheffield Hallam University and a Trustee of The Sleep Charity.</p>' +
                            '<p class="mt-4">Heather was appointed Professor of Child Health in 2014 in recognition of her collaborative work in research innovation in the field of technological solutions for children with long-term conditions. Sleep research is a key area of interest for Heather she has been awarded grants from Health Foundation, SBRI, NIHR and Innovate UK. Heather is sleep and respiratory theme lead for the NIHR Children and Young People MedTech co-operative.</p>',
                        photo: 'images/heather-elphick.png',
                    },
                    {
                        name: 'Zoe Salmon',
                        title: 'TV presenter/Media ambassador',
                        body: '<p>Zoe Salmon is an award-winning television presenter, presenting the BBCs flagship children’s programme Blue Peter. Since then Zoe has been a part of television shows all over the world.</p>' +
                            '<p class="mt-4">Prior to her television career, Zoe read law at Queen’s University, Belfast and went on to qualify as a solicitor. She has a keen interest in wellbeing and recognises the key role that sleep plays.</p>',
                        photo: 'images/zoe-salmon.png',
                    },

                ]

            }
        },
        methods: {

        },
    }
</script>
