<template>
    <div class="text-center py-12 bg-gray-100">
        <div class="container mx-auto px-8 sm:px-4">
        <h2 class="text-pink font-bold font-heading text-4xl">About the manifesto</h2>
        <div class="max-w-xl mx-auto">
            <p class="font-heading font-bold text-lg"> Research clearly demonstrates that if we do not get adequate sleep our
                physical and mental health can be adversely affected. Despite the overwhelming amount of evidence to
                underline the importance of sleep, there is very little support available to improve sleep patterns.
            </p>
            <p class="font-heading font-light text-lg mt-8"> This manifesto sets out to provide the nation with a ‘Wake Up Call’ and to urge the
                government to recognise and act upon the importance of sleep as part of a healthy balanced lifestyle.
            </p>
        </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "About"
    }
</script>

<style scoped>

</style>