<template>
    <div class="max-w-3xl mx-auto policy-content">
       <h2>Our website terms of use</h2>

       <p>We respect the legal stuff – and like to do things properly – so if you would like to read about our website
        terms of use, hold on to your hats and read on…
       </p>

        <h3>1. GENERAL</h3>
        <p>The Sleep Manifesto is a collaborative project between The Sleep Charity and The Sleep Council.
        </p>
        <p>This page (together with the documents referred to in it) sets out the terms on which you may make use of
            this website www.thesleepmaifesto.com (this “Site”). This is the site owned by The Sleep Charity & The Sleep
            Council, UK registered companies and charities. Please refer to their respective site for specific details.
        </p>
        <p>You should read these terms and conditions carefully before using this Site. By accessing or using this Site,
            you agree to be bound by these terms and conditions and our Privacy Policy. If you do not agree to these
            terms, then you should not use this Site.
        </p>
        <p>The material contained in this website is provided for general purposes only and does not constitute as
            professional advice. Users of this Site should take appropriate advice before either taking or not taking
            any action based on information contained in this Site.
        </p>

        <h3>2. AVAILABILITY AND CONDITIONS OF USE</h3>
        <p>This Site is made available to you by The Sleep Charity & The Sleep Council. While we make every effort to
            ensure that the Site is available, please note that it may not be available at all times and you may not
            have uninterrupted use of this Site at all times. We reserve the right to suspend or cease the operation of
            this Site from time to time at our sole discretion.
        </p>
        <p>As a condition of your use of this Site, you agree:
        </p>
        <ul>

            <li>not to use the Site for any purpose that is unlawful under applicable law, or prohibited by these terms
                and conditions;
            </li>
            <li>infringes any person’s intellectual property rights (including any copyright, trademarks or patents)
                and/or gives rise to a cause of action against The Sleep Charity & The Sleep Council or any other
                person; and
            </li>
            <li>not to defame, disparage any person or in a manner which is obscene, derogatory or offensive in any way;
                and
            </li>
            <li>to be responsible for ensuring that your use of this Site is consistent with all applicable laws and
                regulations.
            </li>
        </ul>
       <p> We reserve the right to prevent or suspend your access to this Site if you do not comply with any part of these
        terms and conditions or any applicable law.</p>


        <h3>3. OWNERSHIP, USE AND INTELLECTUAL PROPERTY RIGHTS</h3>
        <p>We are the owner or licensee of all intellectual property rights in this Site and the content published on
            it. That content is protected by copyright laws. We and our licensors reserve all rights. Nothing in these
            terms and conditions grants you any rights in the Site or the content within the Site.
        </p>
        <p>This Site is for your personal and non-commercial use. You may not modify, copy, distribute, transmit,
            display, revise, perform, reproduce, publish, license, create derivative works from, transfer, or sell any
            information or content obtained from this Site, unless expressly authorised by us.
        </p>
        <p>In using the Site you must ensure that you do not infringe the intellectual property rights (including any
            copyright, trademarks or patents) or post or submit material which infringes the rights of another person
            and/or gives rise to a cause of action against The Sleep Charity & The Sleep Council or any other person.
        </p>
        <p>You agree to indemnify us and undertake to keep us indemnified against any and all liabilities, damages,
            losses, costs and expenses (including reasonable legal expenses and amounts paid in settlement of legal
            claims) arising directly or indirectly out of any breach by you of the provisions of these terms and
            conditions.
        </p>

        <h3>4. POSTING OF INFORMATION </h3>
        <p>Except for any personal information we may collect from you which is governed by our Privacy Policy, any
        communication or material you post or transmit to us or this Site is, and will be treated as, non-confidential
        and non-proprietary. It is your responsibility to ensure that any information you post or provide as content for
        use on the Site does not in any way infringe the intellectual property rights (including any copyright,
        trademarks or patents) of any person and, if material you provide for use on the Site or post on the Site
        contains any third party materials, it is your responsibility to obtain evidence of third party consents and
        provide evidence of these to us on request. By transmitting or posting any communication or material, you agree
        that we may use your communication for any purpose, including reproduction, transmission, publication, broadcast
            and posting.</p>

        <p>Although we reserve the right to monitor or review discussions, chats, postings, transmissions, bulletin boards
        and the like on this Site from time to time, we are under no obligation to do so and assume no responsibility or
        liability arising from any such content posted on this Site by anyone other than The Sleep Charity & The Sleep
        Council nor for any error, omission, infringement, defamatory statement, obscenity, or inaccuracy contained in
        any such information on this Site. We shall have the right at any time and for any reason to remove any
            communication or material posted on this Site.</p>


        <h3>5. DISCLAIMERS </h3>
        <p>While we use reasonable efforts to include accurate and up-to-date information on this Site, we do not make any
        representation or give any warranty or promise (whether express or implied) that any information is or remains
            accurate, complete and up to date or fit or suitable for any purpose.</p>

        <p>Any reliance you place on the information on this Site is at your own risk. Nothing in these terms and
            conditions shall operate to prejudice any mandatory statutory requirement or your statutory rights.</p>


        <h3>6. WARRANTIES AND LIMITATION OF LIABILITY</h3>
        <p>You agree that your use of the Site is on an ‘as is’ and ‘as available’ basis. To the maximum extent permitted
        by applicable law, we exclude all liability (whether arising in contract, tort, breach of statutory duty or
            otherwise) which we may otherwise have to you as a result of:</p>

        <ul>
        <li>any error or inaccuracies in any information or material within or relating to this Site;</li>

        <li>the unavailability of this Site for whatsoever reason; and</li>

        <li>any representation or statement made on this Site.</li>
        </ul>
        <p>Under no circumstances shall we be liable to you for any loss or damage suffered (including, without limitation,
        direct or indirect losses) arising from your use of, or reliance on, this Site (including the downloading of any
        materials or information from it). We do not exclude or limit our liability for death or personal injury arising
        from our negligence, for any fraud or fraudulent misrepresentation made by us on this Site or for any other
        statutory rights which are not capable of being excluded.</p>


        <h3>7. LINKS AND THIRD PARTY SITES</h3>
        <p>This Site may contain links or references to external third party websites. If it does so, these links or
            references are provided for your information and convenience only.</p>

        <p>We have no control over third party websites and so accept no responsibility for any content, material or
            information contained in them or your use of them.</p>


        <h3>8. VARIATIONS</h3>
        <p>We reserve the right to change these terms and conditions at any time by amending this page. It is your
        responsibility to check these terms and conditions from time to time to check for such variations. Some
        provisions contained in these terms and conditions may also be superseded by provisions or notices elsewhere on
            this Site.</p>


        <h3>9. GOVERNING LAW AND JURISDICTION</h3>
        <p>These terms and conditions are governed and construed in accordance with the laws of England and Wales and you
            consent to the exclusive jurisdiction of the courts of England.</p>


        <p>This Policy was last updated on 10.02.2020</p>

    </div>
</template>

<script>
    export default {
        name: "TermsAndConditions"
    }
</script>
