<template>
    <div class="max-w-3xl mx-auto my-10">
        <div class="px-8 sm:px-4">


        <h3 class="text-pink font-bold font-heading text-4xl"> Pledge your support</h3>
        <p class="text-blue-dark font-heading text-xl font-bold">
            Sign up to the Charter mailing list to show your support. </p>
        <p class="text-blue-dark font-heading font-thin ">
            We’ll keep you in the loop with developments, news and events, but only intermittently and you can opt out
            at any time.
        </p>

        <form
                name="pledge-support"
                method="post"
                data-netlify="true"
                data-netlify-honeypot="bot-field"

        >
            <input type="hidden" name="form-name" value="pledge-support" />
            <div class="flex flex-col w-full mt-6">
                <label for="first_name">First name</label>
                <input class="border-b border-blue-dark outline-none  py-1" type="text" id="first_name" name="first_name">
            </div>
            <div class="flex flex-col w-full mt-6">
                <label for="last_name">Surname</label>
                <input class="border-b border-blue-dark outline-none  py-1" type="text" id="last_name" name="last_name">
            </div>
            <div class="flex flex-col w-full mt-6">
                <label for="email">Email</label>
                <input class="border-b border-blue-dark outline-none  py-1" type="email" id="email" name="email">
            </div>
            <div class="flex flex-col w-full mt-6">
                <label for="organisation">Organisation</label>
                <input class="border-b border-blue-dark outline-none  py-1" type="text" id="organisation" name="organisation">
            </div>
            <div class="mt-4">
                <input class="mr-4" type="radio" id="agree_to_marketing" name="agree_to_marketing">
                <label for="agree_to_marketing" class="font-heading font-thin">I agree to receive information from The Sleep Manifesto and from The Sleep Charity and The Sleep Council</label>
            </div>


            <button type="submit" class="bg-pink text-white rounded-full py-4 px-10 mt-6 hover:bg-blue-dark hover:text-white transition-all ease duration-150">
                Submit
            </button>
        </form>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ContactForm",
        methods: {
            updatePanelist(ev) {
                this.currentPanelist = ev.target.value
            }
        },
        data() {
            return {
                panelists: ['Evan You', 'Chris Fritz'],
                currentPanelist: 'Evan You'
            }
        }
    }
</script>
